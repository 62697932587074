import React from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { VDOTColorHelper } from "../style/kaiTheme";
import {
  DIR_NS,
  DIR_SE,
  DIR_NE,
  DIR_NW,
  DIR_EB,
  DIR_WB,
  DIR_SB,
  POS_Mapping,
} from "../vjust-analysis-engine/js/Helper/Helper";
import { ArrowMapper, DisplayPositionMapper } from "../Util/IntersectionHelper";
import EditIntersectionDialogDetails from "./EditIntersectionDialogDetails";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const numInputBGFocus = "#FFFFE090"; // lightyellow, 90%
const numInputBGHover = "#FFFFE050"; // lightyellow, 50%

const tooltipStyle = { fontSize: "11pt" };
const cbBoxStyle = { fontSize: "14pt" };
const cbLabelStyle = { fontSize: "11pt" };

const laneNumberStyle = {
  minHeight: "10px",
  height: "3vh",
  width: "1vw",
  borderStyle: "solid",
  borderWidth: "1px",
  background: "yellow",
  fontSize: "1vw",
  fontWeight: 700,
  zIndex: 5,
};

//function to create polygon
function generateFramePolygon(left, top, width, height) {
  const frameArr = [];
  // Add top left corner of frame (p1)
  frameArr.push({ x: 0, y: 0 });
  // Add bottom left corner of frame (p2)
  frameArr.push({ x: 0, y: 100 });
  // Add bottom right corner of frame (p3)
  frameArr.push({ x: 100, y: 100 });
  // Add top right corner of frame (p4)
  frameArr.push({ x: 100, y: 0 });
  // Return to top right corner of frame (p5)
  frameArr.push({ x: 0, y: 0 });
  // Move along top border to interior inset distance of top left corner of cutout (p6)
  frameArr.push({ x: left, y: 0 });
  // Move to top left corner of inset cutout (p7)
  frameArr.push({ x: left, y: top });
  // Move to top right corner of inset cutout (p8)
  frameArr.push({ x: left + width, y: top });
  // Move to bottom right corner of inset cutout (p9)
  frameArr.push({ x: left + width, y: top + height });
  // Move to bottom left corner of inset cutout (p10)
  frameArr.push({ x: left, y: top + height });
  // Return to top border at interior inset distance of cutout (p11)
  frameArr.push({ x: left, y: 0 });
  // Create string
  const ptStr = frameArr.map((pt) => pt.x + "% " + pt.y + "%").join(", ");
  return "polygon(" + ptStr + ")";
}

//function to generate Zone Frame
export function getZoneFrame(zoneBounds) {
  if (!zoneBounds) {
    return undefined;
  }
  return (
    <div
      style={{
        backgroundColor: "black",
        opacity: 0.25,
        position: "absolute",
        zIndex: 2,
        height: "100%",
        width: "100%",
        clipPath: generateFramePolygon(
          zoneBounds.left,
          zoneBounds.top,
          zoneBounds.width,
          zoneBounds.height
        ),
      }}
    />
  );
}

//function to create overlay and highlight zone
export function getZoneHighlight(zoneBounds) {
  if (!zoneBounds) {
    return undefined;
  }
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 2,
        // backgroundColor: "rgba(256, 256, 0, 0.1)",
        // border: "3px solid black",
        border: "3px solid " + VDOTColorHelper.VDOT_Orange,
        height: zoneBounds.height + "%",
        width: zoneBounds.width + "%",
        left: zoneBounds.left + "%",
        top: zoneBounds.top + "%",
      }}
    />
  );
}

//Display inputs for lane configuration
const TempTurnLaneInput = ({
  helperText,
  includeChannelized,
  includeSlip,
  isError,
  labelStr,
  maxLanes,
  minLanes = 0,
  roundBool = true,
  setValue,
  thruBool,
  value,
}) => {
  const sx = { fontSize: "11pt" };
  return (
    <FormControl size="small" fullWidth>
      <InputLabel>
        <span>{labelStr}</span>
      </InputLabel>
      <Select
        error={isError}
        label={labelStr}
        value={value}
        onChange={setValue}
        sx={{
          backgroundColor: numInputBGHover,
          "&:hover": { backgroundColor: numInputBGFocus },
          "&.Mui-focused": { backgroundColor: numInputBGFocus },
        }}
      >
        {minLanes < 1 && (
          <MenuItem value={0} sx={sx}>
            0
          </MenuItem>
        )}
        <MenuItem value={1} sx={sx}>
          1
        </MenuItem>
        {maxLanes >= 2 && (
          <MenuItem value={2} sx={sx}>
            2
          </MenuItem>
        )}

        {roundBool && maxLanes >= 3 && (
          <MenuItem value={3} sx={sx}>
            3
          </MenuItem>
        )}
        {thruBool && maxLanes >= 4 && (
          <MenuItem value={4} sx={sx}>
            4
          </MenuItem>
        )}
        {includeChannelized && (
          <MenuItem value={-1} sx={sx}>
            Channelized
          </MenuItem>
        )}
        {includeSlip && (
          <MenuItem value={-1} sx={sx}>
            Slip Lane
          </MenuItem>
        )}
      </Select>
      {!!helperText && (
        <FormHelperText error={isError}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export const TempFullTurnLaneInput = ({
  errors = [],
  laneConfig,
  zone,
  direction,
  updateInput,
  intxType,
}) => {
  // if we can't find the lane config for this direction and zone, just return
  if (!laneConfig?.[zone]?.[direction]) {
    return;
  }
  // console.log("laneConfig", laneConfig[zone][direction]);
  const { LT, LTShared, RT, RTChan, RTShared, T, UT } =
    laneConfig[zone][direction];

  const getMaximumLanes = (movement) => {
    if (intxType === IntxBuilder.TYPE_TWSC) {
      // this intersection is TWSC
      if (laneConfig.Z5.StopControlDirection.includes(direction)) {
        // TWSC minor approach should not have more than 1 exclusive lane
        return 1;
      }
      if (movement === "T") {
        // TWSC major approach should not have more than 3 thru lanes
        return 3;
      }
      return 99; // an arbitrary big number to allow all lane config inputs
    }
    return 99;
  };

  const utError = errors.find((e) => e.movement === "UT");
  const ltError = errors.find((e) => e.movement === "LT");
  const thruError = errors.find((e) => e.movement === "T");
  const rtError = errors.find((e) => e.movement === "RT");
  return (
    <Grid container spacing={0} sx={{ marginBottom: "10px", width: "100%" }}>
      <Grid item xs={4}>
        {UT >= 0 && (
          <TempTurnLaneInput
            helperText={utError?.helperText}
            isError={!!utError}
            labelStr={"U-Turn"}
            maxLanes={getMaximumLanes("UT")}
            setValue={(evt) => updateInput("UT", evt.target.value)}
            thruBool={false}
            value={laneConfig[zone] ? UT : ""}
          />
        )}
        {LT >= 0 && (
          <TempTurnLaneInput
            helperText={ltError?.helperText}
            isError={!!ltError}
            labelStr={"Left Turn"}
            maxLanes={getMaximumLanes("LT")}
            setValue={(evt) => updateInput("LT", evt.target.value)}
            thruBool={false}
            value={laneConfig[zone] ? LT : ""}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {T >= 0 && (
          <TempTurnLaneInput
            helperText={thruError?.helperText}
            isError={!!thruError}
            labelStr={"Thru"}
            maxLanes={getMaximumLanes("T")}
            setValue={(evt) => updateInput("T", evt.target.value)}
            thruBool={true}
            value={laneConfig[zone] ? T : ""}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {RT >= 0 && (
          <TempTurnLaneInput
            helperText={rtError?.helperText}
            includeChannelized={false}
            isError={!!rtError}
            labelStr={"Right Turn"}
            maxLanes={getMaximumLanes("RT")}
            setValue={(evt) => updateInput("RT", evt.target.value)}
            thruBool={false}
            value={laneConfig[zone] ? RT : ""}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {LTShared >= 0 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": cbBoxStyle }}
                checked={LTShared}
                onChange={(evt) => updateInput("LTShared", evt.target.checked)}
              />
            }
            label={
              <Tooltip
                title={
                  <span style={tooltipStyle}>
                    Left turn lane is shared with through or right movement.
                  </span>
                }
                placement="top"
                arrow
              >
                <span style={cbLabelStyle}>Shared LT</span>
              </Tooltip>
            }
          />
        )}
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4}>
        {RTShared >= 0 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": cbBoxStyle }}
                checked={RTShared}
                onChange={(evt) => updateInput("RTShared", evt.target.checked)}
              />
            }
            label={
              <Tooltip
                title={
                  <span style={tooltipStyle}>
                    Right turn lane is shared with through or left movement.
                  </span>
                }
                placement="top"
                arrow
              >
                <span style={cbLabelStyle}>Shared RT</span>
              </Tooltip>
            }
          />
        )}
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={4} />
      <Grid item xs={4}>
        {RTChan >= 0 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": cbBoxStyle }}
                checked={RTChan}
                onChange={(evt) => updateInput("RTChan", evt.target.checked)}
              />
            }
            label={
              <Tooltip
                title={
                  <span style={tooltipStyle}>
                    Right turn lane is channelized with receiving lane.
                  </span>
                }
                placement="top"
                arrow
              >
                <span style={cbLabelStyle}>Channelized</span>
              </Tooltip>
            }
          />
        )}
      </Grid>
      {errors.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="error" sx={{ mt: "10px" }} variant="outlined">
            {errors[0].message}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export const TempRoundaboutTurnLaneInput = ({
  intxType,
  laneConfig,
  updateInput,
}) => {
  // if we can't find the lane config for this direction and zone, just return
  if (!laneConfig) {
    return null;
  }

  const SLbool = laneConfig.slipLane;
  const EL = laneConfig.numEntryLanes;
  const CL = laneConfig.numCircLanes;

  return (
    <Grid container spacing={0} sx={{ marginBottom: "10px", width: "100%" }}>
      <Grid item xs={4}>
        {EL >= 0 && (
          <TempTurnLaneInput
            labelStr="Entry Lanes"
            maxLanes={2}
            minLanes={intxType === IntxBuilder.TYPE_BOWTIE ? 1 : 0}
            value={laneConfig ? EL : ""}
            setValue={(evt) => updateInput("numEntryLanes", evt.target.value)}
            roundBool={false}
            thruBool={false}
          />
        )}
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={4}>
        {CL >= 0 && (
          <TempTurnLaneInput
            labelStr="Circulating Lanes"
            maxLanes={2}
            minLanes={1}
            roundBool={false}
            setValue={(evt) => updateInput("numCircLanes", evt.target.value)}
            thruBool={false}
            value={laneConfig ? CL : ""}
          />
        )}
      </Grid>

      <Grid item xs={4}>
        {SLbool >= 0 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ "& .MuiSvgIcon-root": cbBoxStyle }}
                checked={SLbool}
                onChange={(evt) => {
                  updateInput("slipLane", evt.target.checked);
                }}
              />
            }
            label={<span style={cbLabelStyle}>Slip Lane</span>}
          />
        )}
      </Grid>
    </Grid>
  );
};

//Display conflict points table
export const ConflictPointsCard = ({ conflictPoints }) => {
  if (!conflictPoints) {
    return null;
  } else {
    return (
      <table
        className={"conflict-points-table"}
        title="Note: Conflict points are by movement rather than by lane"
        style={{ width: "300px" }}
      >
        <thead>
          <tr>
            <th style={{ fontSize: "12px" }}>Type</th>
            <th style={{ fontSize: "12px" }}>Count</th>
            <th style={{ fontSize: "12px" }}>Weight</th>
            <th style={{ fontSize: "12px" }}>Weighted Total Conflict Points</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ fontSize: "12px" }}>Crossing</td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Crossing.Count}
            </td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Crossing.Weight}
            </td>
            <td
              rowSpan={3}
              style={{
                color: "dodgerblue",
                fontWeight: "bold",
                fontSize: "18px",
                borderRight: "1px solid lightblue",
              }}
            >
              {conflictPoints.CP}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>Merging</td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Merging.Count}
            </td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Merging.Weight}
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>Diverging</td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Diverging.Count}
            </td>
            <td style={{ backgroundColor: "gainsboro", fontSize: "12px" }}>
              {conflictPoints.Diverging.Weight}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
};

//Show blocks of Lane Arrows on Page by zone, direction, angle, position
const TempShow = ({ laneConfig, zone, direction, position, zoneBound }) => {
  let topA = position.Arrow.top + zoneBound.top;
  let leftA = position.Arrow.left + zoneBound.left;
  let topL = position.Lane.top + zoneBound.top;
  let leftL = position.Lane.left + zoneBound.left;

  return (
    <>
      {laneConfig[zone]?.[direction]?.UT !== undefined && (
        <div>
          <img
            alt={"U-turn Arrow"}
            src={ArrowMapper.uturnArrow}
            style={{
              position: "absolute",
              left: leftA + "%",
              top: topA + "%",
              height: "6%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <h3
            style={{
              position: "absolute",
              left: leftL + "%",
              top: topL + "%",
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[zone][direction].UT}
          </h3>
        </div>
      )}

      {laneConfig[zone]?.[direction]?.LT !== undefined && (
        <div>
          <img
            alt={"Left Arrow"}
            src={ArrowMapper.leftArrow}
            style={{
              position: "absolute",
              left: leftA + "%",
              top: topA + "%",
              height: "5%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <h3
            style={{
              position: "absolute",
              left: leftL + "%",
              top: topL + "%",
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[zone][direction].LT}
          </h3>
        </div>
      )}

      {laneConfig[zone]?.[direction]?.RT !== undefined && (
        <div>
          <img
            alt={"Right Arrow"}
            src={ArrowMapper.rightArrow}
            style={{
              position: "absolute",
              left: leftA + position.Relative.thru2rt[0] + "%",
              top: topA + position.Relative.thru2rt[1] + "%",
              height: "5%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <h3
            style={{
              position: "absolute",
              left: leftL + position.Relative.thru2rt[0] + "%",
              top: topL + position.Relative.thru2rt[1] + "%",
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[zone][direction].RT}
          </h3>
        </div>
      )}

      {laneConfig[zone]?.[direction]?.T !== undefined && (
        <div>
          <img
            alt={"Thru Arrow"}
            src={ArrowMapper.thruArrow}
            style={{
              position: "absolute",
              left: leftA + position.Relative.lt2thru[0] + "%",
              top: topA + position.Relative.lt2thru[1] + "%",
              height: "5%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <h3
            style={{
              position: "absolute",
              left: leftL + position.Relative.lt2thru[0] + "%",
              top: topL + position.Relative.lt2thru[1] + "%",
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[zone][direction].T}
          </h3>
        </div>
      )}

      {laneConfig[zone]?.[direction]?.RTChan !== undefined &&
        laneConfig[zone][direction].RTChan && (
          <img
            alt={"Right Arrow"}
            src={ArrowMapper.rightArrow_channelized}
            style={{
              position: "absolute",
              left: leftA + position.Relative.thru2rt[0] + "%",
              top: topA + position.Relative.thru2rt[1] + "%",
              height: "5%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
        )}
    </>
  );
};

//function to calculate lane & lane number display position
function getPosition(zoneBound, position, arrowSize) {
  let newPosition = position;

  if (position.Direction === "EB") {
    newPosition.Arrow.top = zoneBound.height - 3 * arrowSize - 1;
    newPosition.Lane.top = newPosition.Arrow.top - 2;
  } else if (position.Direction === "WB") {
    newPosition.Arrow.top = 2 * arrowSize + 1;
    newPosition.Arrow.left = zoneBound.width - 1 - arrowSize;
    newPosition.Lane.top = newPosition.Arrow.top - 2;
    newPosition.Lane.left = newPosition.Arrow.left - 1;
  } else if (position.Direction === "SB") {
    newPosition.Arrow.left = 2 * arrowSize;
    newPosition.Lane.left = newPosition.Arrow.left + 2;
  } else {
    newPosition.Arrow.top = zoneBound.height - 2 - arrowSize;
    newPosition.Arrow.left = zoneBound.width - 3 * arrowSize;
    newPosition.Lane.top = newPosition.Arrow.top - 8;
    newPosition.Lane.left = newPosition.Arrow.left + 2;
  }

  return newPosition;
}

// function to map arrow/lane position with orientation change
function mapPosition(direction, mapping) {
  switch (direction) {
    case "EB":
      return DisplayPositionMapper[mapping.EB];
    case "WB":
      return DisplayPositionMapper[mapping.WB];
    case "SB":
      return DisplayPositionMapper[mapping.SB];
    default:
      return DisplayPositionMapper[mapping.NB];
  }
}

//function to display lane and lane number for one direction
export function DisplayOneDirectionLane({
  inputsLane,
  zone,
  direction,
  position,
  zoneBound,
  orientation,
  intersectionType,
}) {
  if (!inputsLane || !zone || !position || !zoneBound) {
    console.log("DisplayOneDirectionLane: missing inputs");
    return null;
  }

  const tempPosition = () => {
    switch (orientation) {
      case DIR_NW:
        return mapPosition(position.Direction, POS_Mapping[DIR_NS]);
      case DIR_EB:
        return intersectionType === IntxBuilder.TYPE_CGT
          ? mapPosition(position.Direction, POS_Mapping[DIR_NS])
          : position;
      case DIR_NS:
        return intersectionType === IntxBuilder.TYPE_RCUT ||
          intersectionType === IntxBuilder.TYPE_MUT ||
          intersectionType === IntxBuilder.TYPE_PMUT ||
          intersectionType === IntxBuilder.TYPE_PDLT
          ? mapPosition(position.Direction, POS_Mapping[DIR_NS])
          : mapPosition(position.Direction, POS_Mapping[DIR_SE]);
      case DIR_SE:
      case DIR_WB:
        return mapPosition(position.Direction, POS_Mapping[DIR_SE]);
      case DIR_SB:
      case DIR_NE:
        return mapPosition(position.Direction, POS_Mapping[DIR_NE]);
      default:
        return position;
    }
  };
  const showPosition = getPosition(zoneBound, tempPosition(), 5);

  return (
    <TempShow
      laneConfig={inputsLane}
      zone={zone}
      direction={direction}
      position={showPosition}
      zoneBound={zoneBound}
    />
  );
}

const RoundaboutTempShow = ({ laneConfig, direction, position, zoneBound }) => {
  let topA = position.Arrow.top + zoneBound.top + "%";
  let leftA = position.Arrow.left + zoneBound.left + "%";
  let topL = position.Lane.top + zoneBound.top + "%";
  let leftL = position.Lane.left + zoneBound.left + "%";

  let leftA_c = "calc(" + leftA + position.Relative.arr2arr[0] + ")";
  let topA_c = "calc(" + topA + position.Relative.arr2arr[1] + ")";
  let leftL_c = "calc(" + leftL + position.Relative.num2num[0] + ")";
  let topL_c = "calc(" + topL + position.Relative.num2num[1] + ")";

  return (
    <>
      {laneConfig[direction].numEntryLanes !== undefined && (
        <div>
          <img
            alt={"Entry Lanes"}
            src={ArrowMapper.entryArrow}
            style={{
              position: "absolute",
              left: leftA,
              top: topA,
              height: "7%",
              width: "4%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <span
            style={{
              position: "absolute",
              left: leftL,
              top: topL,
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[direction].numEntryLanes}
          </span>
        </div>
      )}

      {laneConfig[direction].numCircLanes !== undefined && (
        <div>
          <img
            alt={"Circulating Lane"}
            src={ArrowMapper.circArrow}
            style={{
              position: "absolute",
              left: leftA_c,
              top: topA_c,
              height: "5%",
              width: "5%",
              transform: position.Arrow.rotate,
              zIndex: 4,
            }}
          />
          <span
            style={{
              position: "absolute",
              left: leftL_c,
              top: topL_c,
              transform: position.Lane.rotate,
              ...laneNumberStyle,
            }}
          >
            {laneConfig[direction].numCircLanes}
          </span>
        </div>
      )}
    </>
  );
};

//function to display lane and lane number for one direction
export function DisplayRoundaboutOneDirectionLane({
  inputsLane,
  direction,
  position,
  zoneBound = { left: 30, top: 20 },
}) {
  return (
    <RoundaboutTempShow
      laneConfig={inputsLane}
      direction={direction}
      position={position}
      zoneBound={zoneBound}
    />
  );
}

// Edit Intersection Dialog component function
export default function EditIntersectionDialog({
  open,
  handleClose,
  saveChanges,
  intersectionName,
  intersectionList,
  defaultVjustValues,
  projectVolumes,
  projectGlobalInputs,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <EditIntersectionDialogDetails
        handleClose={handleClose}
        saveChanges={saveChanges}
        intersectionName={intersectionName}
        intersectionList={intersectionList}
        defaultVjustValues={defaultVjustValues}
        projectVolumes={projectVolumes}
        projectGlobalInputs={projectGlobalInputs}
      />
    </Dialog>
  );
}
